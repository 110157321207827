import styled from "styled-components";

export const EventMapDiv = styled.div`
  width: 100%;
  --backgroundColor: ${(props) => props.backgroundColor || "var(--black)"};
  background-color: var(--backgroundColor);
  & > div {
    width: var(--width);
    padding: 0 var(--outerMargin);
    margin: 50px auto;
    & > div {
      margin-bottom: 25px;
      & h3 {
        color: ${(props) =>
          props.backgroundColor === "var(--blue)"
            ? "var(--white)"
            : "var(--black)"};
        @media (max-width: 767px) {
          font-size: 21px;
        }
      }
    }

    @media (max-width: 767px) {
      --outerMargin: 20px;
    }
  }
  > .noImage {
    padding: 0;
    margin: 0;
    height: 12px;
  }
`;
