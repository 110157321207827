import Layout from "../components/Layout";
import React, { useEffect, useState } from "react";
import { EventHeaderDiv, RelatedEventDiv } from "../styles/event";
import EventDetailHero from "../components/EventDetailHero";
import EventBody from "../components/EventBody";
import EventMap from "../components/EventMap";
import EventList from "../components/EventList";
import ArrowDown from "./../components/icons/ArrowDown";
import { graphql } from "gatsby";
import {
  getFormatShortDayNoTimeZone,
  getTime,
  formatTime,
  getTwoDifferenceDayFormatNoTimeZone,
  getFormatTwoShortDayNoTimeZone,
} from "./../modules/time";
import { getEventTypeFromID } from "./../modules/eventTypes";
import { handleLocation } from "../modules/eventLocation";

const EventPage = ({ data, pageContext }) => {
  const {
    id,
    title,
    headerImage,
    richContent,
    eventStartDateTime,
    eventEndDateTime,
    eventShortDescription,
    qafEventSubTheme,
    qafEventLocation,
    eventType,
    subtitle,
    callToAction,
    eventUserAddToCalendar,
    showOrHideTitleAndSubtitle,
    hideSubtitle,
    registerNowLink,
    eventLink,
    qafEventMapTitle,
    qafEventMapAddress,
    qafEventMapLink,
    qafEventMapImage,
  } = data.craftEntryInterface;

  const [theme, setTheme] = useState(
    qafEventSubTheme?.length ? qafEventSubTheme[0] || "noTheme" : "noTheme"
  );

  let eventStart = eventStartDateTime
    ? formatTime(getTime(eventStartDateTime))
    : null;
  const eventEnd = eventEndDateTime
    ? formatTime(getTime(eventEndDateTime))
    : null;

  let mainDate =
    new Date(eventStartDateTime)?.getMonth() !==
      new Date(eventEndDateTime)?.getMonth() ||
    !eventStartDateTime ||
    !eventEndDateTime
      ? getTwoDifferenceDayFormatNoTimeZone(
          eventStartDateTime,
          eventEndDateTime
        )
      : new Date(eventStartDateTime)?.getDate() ===
        new Date(eventEndDateTime)?.getDate()
      ? getFormatShortDayNoTimeZone(eventStartDateTime, true)
      : getFormatTwoShortDayNoTimeZone(
          eventStartDateTime,
          eventEndDateTime,
          true
        );

  useEffect(() => {
    if (eventType.length && eventType[0] === "foodDrink") {
      setTheme(null);
    } else {
      switch (theme) {
        case "cyborg":
          setTheme("cyborg");
          break;
        case "nature":
          setTheme("nature");
          break;
        case "childhood":
          setTheme("childhood");
          break;
        default:
          setTheme(null);
      }
    }
  }, []);

  const relatedEventsList = data?.allEvents?.nodes;

  const communityMembers = qafEventLocation?.length
    ? qafEventLocation.filter(
        (item) => item?.participantType && item?.participantType?.length
      )
    : [];

  useEffect(() => {
    for (let i = 0; i < communityMembers?.length; i++) {
      const relatedE = relatedEventsList.filter((e) => {
        const checkedArray = e?.qafEventLocation?.length
          ? e?.qafEventLocation.filter(
              (item) => item?.participantType && item?.participantType?.length
            )
          : [];

        return checkedArray.find(
          (item) => item?.id === communityMembers[i]?.id
        );
      });
      communityMembers[i].relatedEvents = relatedE;
    }
  }, []);

  return (
    <Layout backgroundColor={"var(--black)"} skipHeader headerBlack={true}>
      <EventHeaderDiv theme={theme}>
        <EventDetailHero
          theme={theme}
          secondline={
            eventType.length && eventType[0] === "foodDrink"
              ? "culinary"
              : getEventTypeFromID(eventType[0])
          }
          titleText={title}
          subTitleText={
            eventType.length && eventType[0] === "foodDrink" ? "" : subtitle
          }
          isDark={true}
          fontSizeH5="21px"
          headerImages={headerImage}
          carousel={eventType.length && eventType[0] === "foodDrink"}
        />
      </EventHeaderDiv>

      <EventBody
        subTitle={eventShortDescription}
        addToCalendar={
          eventUserAddToCalendar && {
            title: title,
            description: eventShortDescription || subtitle || "",
            location: handleLocation(qafEventLocation),
            startTime: eventStartDateTime,
            endTime: eventEndDateTime,
          }
        }
        date={mainDate}
        startTime={eventStart}
        endTime={eventEnd}
        venue={handleLocation(qafEventLocation)}
        theme={theme}
        mainColumn={richContent}
        leftInfo={eventType.length && eventType[0] !== "foodDrink"}
        communityInfo={
          eventType.length && eventType[0] === "foodDrink"
            ? qafEventLocation.find(
                (item) => item?.id && item?.id.includes("participants")
              )
            : null
        }
        isSmallTitle={true}
        eventType={eventType?.length ? eventType[0] : null}
        participants={
          eventType[0] !== "foodDrink"
            ? qafEventLocation?.length && communityMembers
            : null
        }
      />

      <EventMap theme={theme} qafEventMapImage={qafEventMapImage} />

      {data?.events && data?.events?.nodes && data?.events?.nodes?.length ? (
        <RelatedEventDiv>
          <p>
            <span>Related Events</span> <ArrowDown />
          </p>
          <EventList events={data?.events?.nodes} />
        </RelatedEventDiv>
      ) : null}
    </Layout>
  );
};

export default EventPage;

export const pageQuery = graphql`
  query ($id: String!, $myType: String!) {
    eventGlobalData: allCraftSiteQafSettingsGlobalSet {
      nodes {
        venuesLocation
        siteQAFSettingAddress
        siteQAFSettingMapButtonLink
      }
    }
    craftEntryInterface(id: { eq: $id }) {
      ... on Craft_qafEvents_qafEvent_Entry {
        id
        title
        eventType
        eventStartDateTime
        eventEndDateTime
        eventUserAddToCalendar
        showOrHideTitleAndSubtitle
        hideSubtitle
        registerNowLink
        headerImage: qafEventHeaderImage {
          ... on Craft_eventsAssets_Asset {
            wide: localFile {
              publicURL
              childImageSharp {
                fixed(width: 1440, quality: 100, fit: COVER) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        qafEventMapImage {
          ... on Craft_qafAssets_Asset {
            id
            localFile {
              childImageSharp {
                fluid(
                  maxWidth: 1240
                  maxHeight: 500
                  quality: 100
                  fit: COVER
                ) {
                  src
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        eventLink
        qafEventSubTheme
        qafEventLocation {
          slug
          title
          ... on Craft_participants_default_Entry {
            id
            participantLocation {
              id
              title
            }
            communityMemberOpeningHours
            communityMemberSlug
            communityMemberWebsite
            communityMemberEmailAddress
            communityMemberDescription
            communityMemberContactDetails {
              col1
              col2
            }
            participantType
            headerImage: qafParticipantHeaderImage {
              ... on Craft_communityAssets_Asset {
                localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 295, quality: 100, fit: COVER) {
                      src
                    }
                  }
                }
              }
            }
          }
          ... on Craft_maplocation_default_Entry {
            locationId
            locationName
            title
            allowClick
          }
        }
        subtitle
        eventShortDescription
        callToAction
        richContent {
          ... on Craft_richContent_matterportEmbed_BlockType {
            typeHandle
            matterportUrl
          }
          ... on Craft_richContent_podiantPlayer_BlockType {
            podiantUrl
            typeHandle
          }
          ... on Craft_richContent_anghamiPlayer_BlockType {
            anghamiUrl
            typeHandle
          }
          ... on Craft_richContent_soundcloudPlayer_BlockType {
            soundcloudUrl
            typeHandle
          }
          ... on Craft_richContent_spotifyPlayer_BlockType {
            spotifyUrl
            typeHandle
          }
          ... on Craft_richContent_image_BlockType {
            typeHandle
            imageWithCaptionImage {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, quality: 100, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            imageWithCaptionCaption
          }
          ... on Craft_richContent_slideShow_BlockType {
            typeHandle
            images {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            carouselCaption
          }
          ... on Craft_richContent_richText_BlockType {
            typeHandle
            richText
          }
          ... on Craft_richContent_imageSlider_BlockType {
            typeHandle
            imageSliderImages {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            imageSliderCaptions {
              captionLine4
              captionLine3
              captionLine2
              captionLine1
            }
          }
          ... on Craft_richContent_videoWithCaption_BlockType {
            typeHandle
            videoWithCaptionCaption
            videoWithCaptionVideoOnTheLeft
            videoWithCaptionUseCredit
            videoWithCaptionUrl
            videoWithCaptionVideo {
              url
            }
          }
          ... on Craft_richContent_photoWithQuote_BlockType {
            typeHandle
            photoWithQuoteImage {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            photoWithQuoteImageOnTheLeft
            photoWithQuoteImageQuote
            photoWithQuoteImageQuoteAttribution
          }
        }
      }
    }
    events: allCraftQafEventsQafEventEntry(
      filter: { id: { ne: $id }, eventType: { glob: $myType } }
      limit: 6
    ) {
      nodes {
        id
        title
        isStaging
        eventEndDateTime
        eventStartDateTime
        eventShortDescription
        qafEventSubTheme
        qafEventLocation {
          title
          ... on Craft_participants_default_Entry {
            id
            participantLocation {
              id
              title
            }
            communityMemberOpeningHours
            communityMemberSlug
            communityMemberWebsite
            communityMemberEmailAddress
            communityMemberDescription
            communityMemberContactDetails {
              col1
              col2
            }
            participantType
          }
          ... on Craft_maplocation_default_Entry {
            locationId
            locationName
            title
            allowClick
          }
        }
        slug
        eventType
        headerImage: qafEventHeaderImage {
          ... on Craft_eventsAssets_Asset {
            cardImage: localFile {
              publicURL
              childImageSharp {
                fixed(width: 400, quality: 100, fit: COVER) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
    allEvents: allCraftQafEventsQafEventEntry {
      nodes {
        id
        title
        isStaging
        eventEndDateTime
        eventStartDateTime
        eventShortDescription
        qafEventSubTheme
        qafEventLocation {
          title
          ... on Craft_participants_default_Entry {
            id
            participantLocation {
              id
              title
            }
            communityMemberOpeningHours
            communityMemberSlug
            communityMemberWebsite
            communityMemberEmailAddress
            communityMemberDescription
            communityMemberContactDetails {
              col1
              col2
            }
            participantType
          }
          ... on Craft_maplocation_default_Entry {
            locationId
            locationName
            title
            allowClick
          }
        }
        slug
        eventType
        headerImage: qafEventHeaderImage {
          ... on Craft_eventsAssets_Asset {
            cardImage: localFile {
              publicURL
              childImageSharp {
                fixed(fit: COVER, width: 172) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`;
