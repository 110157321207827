import styled from "styled-components";

export const EventTextDetailDiv = styled.div`
  --color: ${(props) => props.color || "var(--light)"};
  --backgroundColor: transparent;
  --accentColor: var(--red);
  --titleSize: 62px;
  width: var(--width);
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 25px var(--outerMargin) 25px var(--outerMargin);
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--backgroundColor);
  color: var(--color);
  & h5 {
    font-size: 21px;
    margin-bottom: 15px;
  }
  & h2 {
    font-size: var(--titleSize);
  }

  @media (max-width: 767px) {
    --outerMargin: 0px;
    --titleSize: 36px;
    & h5 {
      font-size: 18px;
    }
  }
`;

export const FluidImageDiv = styled.div`
  --backgroundColor: ${(props) => props.imageBackgroundColor || "var(--black)"};
  width: 100%;
  max-height: 512px;
  padding-bottom: 12px;
  background-color: var(--backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
  & img {
    width: 100%;
    max-width: 1440px;
    max-height: 500px !important;
    object-fit: cover;
  }
  & .gatsby-image-wrapper {
    max-height: 500px;
    & div {
      padding-bottom: 512px !important;
    }
  }
`;
