import MiscSocialSmallParticipant from "../icons/MiscSocialSmallParticipant";
import { SmallButtonParticipant } from "../ButtonsParticipants";
import { H4Strong } from "./../Typography";
import { OverlayDiv, RelatedEventDiv } from "./elements";
import React from "react";
import Img from "gatsby-image";
import CloseButton from "./../icons/CloseButton";
import { navigate } from "gatsby";
import ArrowDown from "../icons/ArrowDown";
import EventList from "../../components/EventList";

const EventParticipantsPopup = ({
  communityMember,
  photoCoordinates,
  holderCoordinates,
  closeThis,
  randomNumber,
}) => {
  const myLeft = 0 - holderCoordinates.x - 50;
  const myTop = 0 - holderCoordinates.top - 88;
  const myColumn = Math.floor(myLeft / 315); // 0, 1, 2, or 3

  const participantLinks = {
    linkedin: communityMember.communityMemberContactDetails?.find(
      (item) => item.method === "LinkedIn"
    )?.value,
    twitter: communityMember.communityMemberContactDetails?.find(
      (item) => item.method === "Twitter"
    )?.value,
    facebook: communityMember.communityMemberContactDetails?.find(
      (item) => item.method === "Facebook"
    )?.value,
    instagram: communityMember.communityMemberContactDetails?.find(
      (item) => item.method === "Instagram"
    )?.value,
  };

  return (
    <OverlayDiv
      left={myLeft}
      top={myTop}
      myColumn={myColumn}
      backgroundColorDiv={randomNumber}
    >
      <div>
        <div id={"image"}>
          {communityMember.headerImage &&
          communityMember.headerImage.length &&
          communityMember.headerImage[0].localFile &&
          communityMember.headerImage[0].localFile.childImageSharp ? (
            <Img
              fixed={
                communityMember.headerImage[0].localFile.childImageSharp.fixed
              }
              style={{
                width: "100%",
                aspectRatio: "1/1",
                maxWidth: "259px",
                maxHeight: "259px",
              }}
            />
          ) : communityMember.headerImage.length &&
            communityMember.headerImage[0].localFile ? (
            <img src={communityMember.headerImage[0].localFile.publicURL} />
          ) : (
            <div className="placeholder" />
          )}
          {/* <Link to={`/community/${communityMember.communityMemberSlug}/`}>
                <SmallButtonParticipant color={"var(--red)"} backgroundColor={"transparent"}>
                  Find out more
                </SmallButtonParticipant>
              </Link> */}
          <MiscSocialSmallParticipant
            color={randomNumber || "var(--black)"}
            participantLinks={participantLinks}
          />
        </div>
        {/* <div id={"image-contacts"}>         
          </div> */}
        <div
          id={"details"}
          dangerouslySetInnerHTML={{
            __html: communityMember.communityMemberDescription,
          }}
        ></div>
        <div id={"contacts"} className="contactdetails">
          <div>
            <H4Strong>TYPE</H4Strong>
            {communityMember?.participantType?.length ? (
              <p>{communityMember.participantType[0]}</p>
            ) : null}
          </div>
          <div>
            <H4Strong>HOURS</H4Strong>
            <p className="canwrap">
              {communityMember.communityMemberOpeningHours}
            </p>
          </div>
          <div>
            {communityMember?.participantLocation?.length ? (
              <>
                <H4Strong>Location</H4Strong>
                <p className="canwrap">
                  {communityMember?.participantLocation[0]?.title}
                </p>
                <SmallButtonParticipant
                  onClick={() => {
                    navigate(
                      `/programme/map?location=${encodeURI(
                        communityMember?.participantLocation?.length
                          ? communityMember?.participantLocation[0].title
                          : ""
                      )}`
                    );
                    // goToMap(participantLocation?.title);
                  }}
                  color={"var(--black)"}
                  backgroundColor={"transparent"}
                  randomNumber={randomNumber}
                >
                  View on map
                </SmallButtonParticipant>
              </>
            ) : null}
          </div>
        </div>
      </div>

      {communityMember?.relatedEvents &&
        communityMember?.relatedEvents.length > 0 && (
          <RelatedEventDiv backgroundColorDiv={randomNumber}>
            <p>
              <span>Related Events</span> <ArrowDown />
            </p>
            <EventList
              events={
                communityMember?.relatedEvents.length < 4
                  ? communityMember?.relatedEvents
                  : communityMember?.relatedEvents.slice(0, 4)
              }
              numberColumns={2}
            />
          </RelatedEventDiv>
        )}

      <CloseButton
        color={"var(--black)"}
        hoverColor={"var(--white)"}
        onClick={closeThis}
      />
    </OverlayDiv>
  );
};

export default EventParticipantsPopup;
