import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { H5SmallMedium, H2BigStrong, H2Big } from "./../Typography";
import { EventTextDetailDiv, FluidImageDiv } from "./elements";
import ImagesCarousel from "./../ImagesCarousel";
import Img from "gatsby-image";
import { AnimationOnScroll } from "react-animation-on-scroll";

const EventDetailHero = ({
  topline,
  secondline,
  titleText,
  subTitleText,
  theme,
  textBackgroundColor,
  headerImages,
  carousel,
}) => {
  const color =
    (theme === "cyborg" && "var(--blue)") ||
    (theme === "nature" && "var(--green2)") ||
    (theme === "childhood" && "var(--pink)") ||
    "var(--green)";
  return (
    <Fragment>
      <AnimationOnScroll
        animatePreScroll={true}
        animateIn="animate__zoomInDown"
        // animateOut="animate__zoomOutUp"
        animateOnce
        duration={1.75}
        offset={50}
      >
        <EventTextDetailDiv
          textBackgroundColor={textBackgroundColor || "transparent"}
          color={color || "var(--red)"}
        >
          <H5SmallMedium style={{ color: "var(--blue)" }}>
            {topline}
          </H5SmallMedium>
          <H5SmallMedium style={{ color: "var(--white)" }}>
            {secondline}
          </H5SmallMedium>
          <div>
            <H2BigStrong>{titleText}</H2BigStrong>
            <H2Big>{subTitleText}</H2Big>
          </div>
        </EventTextDetailDiv>
      </AnimationOnScroll>
      {!carousel && headerImages && headerImages.length && (
        <FluidImageDiv imageBackgroundColor={color}>
          {headerImages[0].wide && headerImages[0].wide.childImageSharp ? (
            <Img fixed={headerImages[0].wide.childImageSharp.fixed} />
          ) : null}
        </FluidImageDiv>
      )}
      {carousel && headerImages && headerImages.length && (
        <ImagesCarousel
          images={headerImages.map((item) => ({
            fixed: item?.wide?.childImageSharp?.fixed,
          }))}
          controlColor={"var(--green)"}
          defaultSlide={"center"}
          imagesAreUrls={true}
        />
      )}
    </Fragment>
  );
};

export default EventDetailHero;

EventDetailHero.propTypes = {
  topline: PropTypes.string,
  titleImage: PropTypes.element,
  titleText: PropTypes.string,
  subTitleText: PropTypes.string,
};

EventDetailHero.defaultProps = {};
