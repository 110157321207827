import styled from "styled-components";

export const OverlayDiv = styled.div`
  /**
* myColumn comes in as 0, 1, 2, or 3
*
* if it's 0 or 1, text block should be on right
* if it's 1 or 2, text block should be on the left
 */
  --yellow2: #ffc327;
  --purple2: #8e69ee;
  --backgroundColor: ${(props) => {
    if (props.backgroundColorDiv === 1) {
      return "var(--red)";
    } else if (props.backgroundColorDiv === 3) {
      return "var(--yellow2)";
    } else if (props.backgroundColorDiv === 2) {
      return "var(--purple2)";
    } else {
      return "var(--green)";
    }
  }};

  --borderTopDiv: ${(props) => {
    if (
      props.backgroundColorDiv === 1 ||
      props.backgroundColorDiv === 2 ||
      props.backgroundColorDiv === 3
    ) {
      return "var(--white)";
    } else {
      return "var(--black)";
    }
  }};
  --borderTopPicture: ${(props) => {
    if (props.backgroundColorDiv === 1) {
      return "var(--blue)";
    } else if (props.backgroundColorDiv === 3) {
      return "var(--green2)";
    } else if (props.backgroundColorDiv === 2) {
      return "var(--pink)";
    } else {
      return "var(--black)";
    }
  }};

  /* position: absolute; */
  /* left: ${(props) => props.left}px;
  left: ${(props) =>
    props.myColumn < 2
      ? 25 + 25 * props.myColumn
      : 25 + 25 * (props.myColumn - 2)}px; */
  /* top: ${(props) => props.top}px; */

  //fixed
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  max-height: 846px;
  overflow-y: scroll;
  overflow-x: hidden;

  width: calc(var(--width) - 410px);
  z-index: 9;
  & > div:first-child {
    order: 0 !important;
    background-color: var(--backgroundColor);
    padding: 88px 50px 105px;
    border-top: 4px solid transparent;
    border-top-color: var(--borderTopDiv);
    min-width: 100% !important;
    display: flex;
    gap: 2%;
    /* flex-direction: ${(props) =>
      props.myColumn < 2 ? "row" : "row-reverse"}; */
    flex-direction: row;
    margin-bottom: 0 !important;
    & div:first-child {
      & div {
        margin-bottom: 20px;
      }
    }
    & div img {
      border-top: 10px solid transparent;
      border-top-color: var(--borderTopPicture);
    }
    > div#image {
      flex: 0 0 28%;
    }
    > div#details {
      flex: 0 0 40%;
    }
    > div#contacts {
      flex: 0 0 28%;
    }

    /*** THIS IS THE IMAGE + CONTACTS DIV */
    /* & > div#image-contacts {
      display: flex;
      width: 610px;
      justify-content: space-between;
      flex-direction: ${(props) =>
      props.myColumn === 1 || props.myColumn === 3 ? "row-reverse" : "row"};
      & > div {
        width: 295px;
        & > img {
          width: 295px;
          height: auto;
        }
        & > div + div {
          margin-top: 20px;
        }
      }
      & h4 {
        padding-top: 8px;
        color: var(--red);
        border-top: 4px solid var(--red);
      }
      & p {
        font-size: 18px;
        font-weight: 400;
        line-height: 25.2px;
        margin: 0;
        & + svg {
          margin-top: 18px;
        }
      }
      & > div {
        margin-left: 25px;
        width: 580px;
        min-width: 295px;
        & h3 {
          &:first-of-type {
            color: var(--red);
            & + h3 {
              margin-bottom: 30px;
            }
          }
        }
        & h4 {
          font-weight: bold;
          font-size: 14px;
          letter-spacing: 0.84px;
          margin-bottom: 4px;
        }
        & p {
          margin: 0;
          font-weight: 400;
          font-size: 18px;
          line-height: 25.2px;
          & a {
            text-decoration: underline;
            &.nounderline {
              text-decoration: none;
            }
            &:hover {
              color: var(--red);
            }
          }
        }
        & div strong {
          color: var(--red);
        }
      }
    } */
    & > div#details {
      & p {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.32px;
        font-weight: 400;
        margin: 0;
        color: var(--borderTopDiv);
        & strong {
          color: var(--borderTopPicture);
        }
      }
    }
    & button {
      margin-top: 20px;
    }
  }

  & > div:nth-of-type(2) {
    background-color: var(--black);
    padding: 50px;
    width: 1030px;
    & > p {
      line-height: 125%;
      letter-spacing: 0.01em;
      margin-bottom: 20px;
    }
    & > svg {
      height: 10px;
    }
  }

  & > svg {
    position: absolute;
    top: 29px;
    right: 26px;
    cursor: pointer;
  }
  & .contactdetails {
    width: 100%;
    overflow-x: hidden;
    color: var(--borderTopDiv);
    & div {
      border-top: 2px solid var(--borderTopDiv);
      width: 100%;
      & h4 {
        margin-top: 10px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 21px !important;
        line-height: 140%;
      }
      & p {
        max-width: 100%;
        white-space: nowrap;
        overflow-x: auto;
        font-size: 19px !important;
        margin: 0 0 18px 0 !important;
        line-height: unset !important;
        &.canwrap {
          white-space: initial;
          overflow-x: initial;
        }
        a {
          color: var(--black);
        }
      }
    }
    /* & p {
      max-width: 100%;
      white-space: nowrap;
      overflow-x: auto;
      &.canwrap {
        white-space: initial;
        overflow-x: initial;
      }
      a {
        color: var(--black);
      }
    } */
  }
  @media (max-width: 1439px) {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    max-height: 846px;
    z-index: 999;
  }
  @media (max-width: 1023px) {
    flex-wrap: nowrap !important;
    & > div {
      /* flex-direction: column !important; */
      & > div:first-of-type {
        margin-bottom: 25px;
      }
      & > div:nth-of-type(2) {
        & > p {
          line-height: 30px !important;
          letter-spacing: 0 !important;
        }
      }
      & > div:nth-of-type(3) {
        & > div {
          & > h4,
          & > p {
            white-space: normal;
          }
        }
        & > div:nth-of-type(3) {
          & > button {
            padding: 0 10px !important;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    position: fixed !important;
    width: 100% !important;
    height: 100% !important;
    & > div:first-child {
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      &::-webkit-scrollbar {
        display: none;
      }
      min-height: 100% !important;
      padding: 82px 20px 36px !important;
      /* height: 100% !important; */
      overflow-y: scroll;
      margin-right: 0 !important;
      margin-bottom: 0 !important;
      flex-direction: column !important;
      & p {
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        &::-webkit-scrollbar {
          display: none;
    }
      }
      & > div:nth-of-type(1) {
        display: flex !important;
        flex-direction: column !important;
        & > div:first-of-type {
          width: 335px !important;
          height: 335px !important;
          align-self: center;
        }
      }
      & > div:nth-of-type(2) {
        max-width: 100% !important;
        margin-left: 0 !important;
        margin-bottom: 25px !important;
        max-height: unset !important;
        overflow: unset !important;
        & > p {
          font-size: 24px !important;
          line-height: 30px !important;
        }
      }
      & > div:nth-of-type(3) {
        margin-left: 0px !important;
        overflow-x: visible !important;
        & > div > h4 {
          font-size: 21px !important;
        }
        & > div > p {
          font-size: 19px !important;
        }
        & > div:last-of-type {
          & > button {
            font-weight: 400 !important;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    & > div:nth-of-type(2) {
      margin-top: 0px !important;
      & > p {
        font-weight: 400 !important;
        font-size: 24px !important;
        & > svg {
          height: 10px !important;
        }
      }
      height: 100%;
      padding: 36px 0 0 20px;
    }
  }
`;

export const RelatedEventDiv = styled.div`
  width: var(--width);
  max-width: var(--width);
  height: fit-content !important;
  padding: 50px var(--outerMargin);
  margin: 0 auto;
  border: 10px solid
    ${(props) => {
      if (props.backgroundColorDiv === 1) {
        return "var(--red)";
      } else if (props.backgroundColorDiv === 3) {
        return "var(--yellow2)";
      } else if (props.backgroundColorDiv === 2) {
        return "var(--purple2)";
      } else {
        return "var(--green)";
      }
    }};
  border-top: none;
  & > p {
    color: var(--white);
    margin: 0 0 12px 0;
    font-size: 32px;
    font-weight: 400;
    & > span {
      margin-right: 25px;
    }
  }
  @media (max-width: 768px) {
    --outerMargin: 20px;
    padding: 36px 20px !important;
  }
`;
