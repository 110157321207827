import React, { Fragment } from "react";
import { H3Small, H3SmallStrong } from "./../Typography";
import { EventMapDiv } from "./elements";
import Img from "gatsby-image";
import { navigate } from "gatsby";

const EventMap = ({ theme, qafEventMapImage }) => {
  return (
    <Fragment>
      <EventMapDiv
        backgroundColor={
          (theme === "cyborg" && "var(--blue)") ||
          (theme === "nature" && "var(--green2)") ||
          (theme === "childhood" && "var(--pink)") ||
          "var(--green)"
        }
      >
        <div className={qafEventMapImage?.length ? null: "noImage"}>
          {qafEventMapImage?.length ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                maxWidth: "1240px",
                maxHeight: "500px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/programme/map")}
            >
              <Img
                style={{
                  maxWidth: "1240px",
                  maxHeight: "500px",
                }}
                fluid={qafEventMapImage[0]?.localFile?.childImageSharp?.fluid}
                alt="Event Map"
              />
            </div>
          ) : (
            null
          )}
        </div>
      </EventMapDiv>
    </Fragment>
  );
};

export default EventMap;
